exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-aviso-de-privacidad-tsx": () => import("./../../../src/pages/aviso-de-privacidad.tsx" /* webpackChunkName: "component---src-pages-aviso-de-privacidad-tsx" */),
  "component---src-pages-blog-[id]-tsx": () => import("./../../../src/pages/blog/[id].tsx" /* webpackChunkName: "component---src-pages-blog-[id]-tsx" */),
  "component---src-pages-blog-[tag]-tag-tsx": () => import("./../../../src/pages/blog/tag/[tag].tsx" /* webpackChunkName: "component---src-pages-blog-[tag]-tag-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-blog-search-index-tsx": () => import("./../../../src/pages/blog/search/index.tsx" /* webpackChunkName: "component---src-pages-blog-search-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-landing-adquisicion-de-vivienda-index-tsx": () => import("./../../../src/pages/landing/adquisicion-de-vivienda/index.tsx" /* webpackChunkName: "component---src-pages-landing-adquisicion-de-vivienda-index-tsx" */),
  "component---src-pages-landing-rally-hipotecario-2023-index-tsx": () => import("./../../../src/pages/landing/rally-hipotecario-2023/index.tsx" /* webpackChunkName: "component---src-pages-landing-rally-hipotecario-2023-index-tsx" */),
  "component---src-pages-landing-summit-creditaria-2023-index-tsx": () => import("./../../../src/pages/landing/summit-creditaria-2023/index.tsx" /* webpackChunkName: "component---src-pages-landing-summit-creditaria-2023-index-tsx" */),
  "component---src-pages-medios-tsx": () => import("./../../../src/pages/medios.tsx" /* webpackChunkName: "component---src-pages-medios-tsx" */),
  "component---src-pages-payment-confirmation-tsx": () => import("./../../../src/pages/payment-confirmation.tsx" /* webpackChunkName: "component---src-pages-payment-confirmation-tsx" */),
  "component---src-pages-por-que-confiar-en-kredi-tsx": () => import("./../../../src/pages/por-que-confiar-en-kredi.tsx" /* webpackChunkName: "component---src-pages-por-que-confiar-en-kredi-tsx" */),
  "component---src-pages-preguntas-frecuentes-tsx": () => import("./../../../src/pages/preguntas-frecuentes.tsx" /* webpackChunkName: "component---src-pages-preguntas-frecuentes-tsx" */),
  "component---src-pages-productos-adquisicion-de-vivienda-tsx": () => import("./../../../src/pages/productos/adquisicion-de-vivienda.tsx" /* webpackChunkName: "component---src-pages-productos-adquisicion-de-vivienda-tsx" */),
  "component---src-pages-productos-mejora-de-hipoteca-tsx": () => import("./../../../src/pages/productos/mejora-de-hipoteca.tsx" /* webpackChunkName: "component---src-pages-productos-mejora-de-hipoteca-tsx" */),
  "component---src-pages-simulador-de-credito-hipotecario-tsx": () => import("./../../../src/pages/simulador-de-credito-hipotecario.tsx" /* webpackChunkName: "component---src-pages-simulador-de-credito-hipotecario-tsx" */),
  "component---src-pages-sobre-nosotros-tsx": () => import("./../../../src/pages/sobre-nosotros.tsx" /* webpackChunkName: "component---src-pages-sobre-nosotros-tsx" */),
  "component---src-pages-terminos-y-condiciones-tsx": () => import("./../../../src/pages/terminos-y-condiciones.tsx" /* webpackChunkName: "component---src-pages-terminos-y-condiciones-tsx" */)
}

